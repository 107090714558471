<template>
	<div class="online-order">
		<div class="order-ways rsc">
			<div class="way" @click="handleTab(i)" :class="{'active': index == i}" v-for="(item, i) in ways" :key="i">
				{{item}}
			</div>
		</div>

		<div class="add-number">
			<div class="panel" v-show="index > -1 && index != 3 && !step">
				<div class="subtitle rbc">
					<span>添加单号</span>
					<el-button type="primary" @click="addForm('ruleForm')">+ 添加</el-button>
				</div>

				<div class="add">
					<el-form :inline="true" :model="form" status-icon class="demo-form-inline" :rules="rules"
						ref="ruleForm" :status-icon="false">
						<el-form-item label="快递公司" prop="delivery">
							<el-input v-model="form.origin_delivery"></el-input>
						</el-form-item>
						<el-form-item label="单号" prop="orderId">
							<el-input v-model="form.origin_order_id"></el-input>
						</el-form-item>
						<el-form-item label="物品" prop="name">
							<el-input v-model="form.product_name"></el-input>
						</el-form-item>
						<el-form-item label="数量" prop="num">
							<el-input v-model.number="form.product_num">
							</el-input>
						</el-form-item>
						<el-form-item label="单价" prop="price">
							<el-input :clearable="true" v-model.number="form.product_price"></el-input>
						</el-form-item>
						<!-- <el-form-item>
							<el-button type="primary" @click="addForm('ruleForm')">+ 添加</el-button>
						</el-form-item> -->
					</el-form>
				</div>

				<div class="confirm">

				</div>


				<div class="number">
					<el-table max-height="350" :data="tableData" stripe border style="width: 100%"
						:header-cell-style="{background:'#EBF6FC',color:'#999999'}">
						<!-- <el-table-column type="selection" width="45" /> -->
						<el-table-column type="index" width="45" />
						<el-table-column prop="origin_order_id" label="单号" width="140" />
						<el-table-column prop="origin_delivery" label="快递公司" width="150" />
						<el-table-column prop="product_name" label="物品名称" width="220" />
						<el-table-column prop="product_num" label="数量" width="120" />
						<el-table-column prop="product_price" label="单价" width="120" />
						<el-table-column fixed="right" label="操作" width="120">
							<template slot-scope="scope">
								<el-button @click="editRow(scope.$index)" type="text" size="small">
									编辑</el-button>
								<el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text"
									size="small">
									<span style="color: #ff5500">删除</span>
								</el-button>
							</template>
						</el-table-column>

					</el-table>
				</div>

				<div class="subtitle rbc">
					<span>添加代运信息</span>
				</div>

				<div class="add-info">

					<el-form :inline="true" label-position="left" :model="formInline" label-width="100px"
						class="demo-form-inline">
						<el-form-item label="收件人">
							<el-input v-model="formInline.receive_user" placeholder="收件人"></el-input>
						</el-form-item>
						<el-form-item label="手机号">
							<el-input v-model="formInline.receive_phone" placeholder="手机号"></el-input>
						</el-form-item>
						<el-form-item label="收件人地址">
							<el-input v-model="formInline.receive_address" placeholder="收件人地址"></el-input>
						</el-form-item>
					</el-form>
					<div class="mian-btn">
						<el-button type="primary" @click="handleOrderNext">下一步</el-button>
					</div>
				</div>
			</div>

			<!-- 代购 -->
			<div class="panel" v-show="index == 3 && !step">
				<div class="subtitle rbc">
					<span>下单信息</span>
				</div>

				<div class="purchasing">
					<el-form :model="form1" label-position="left" :rules="rules1" ref="ruleForm2" label-width="90px"
						:inline="true">
						<el-form-item label="收件人" prop="username">
							<el-input v-model="form1.username" clearable placeholder="请输入姓名">
							</el-input>
						</el-form-item>
						<el-form-item label="手机号" prop="phone">
							<el-input v-model="form1.phone" clearable placeholder="请输入手机号">
							</el-input>
						</el-form-item>
						<el-form-item label="收件地址" prop="address">
							<el-cascader size="large" popper-class="cascader" :props="{ expandTrigger: 'hover' }"
								:options="options" @change="handleChange">
							</el-cascader>
						</el-form-item>
						<el-form-item label="详细地址" prop="address_detail">
							<el-input v-model="form1.address_detail" clearable placeholder="请输入详细地址">
							</el-input>
						</el-form-item>
						<el-form-item label="代购物品" prop="product">
							<el-input v-model="form1.product" clearable placeholder="请输入代购物品">
							</el-input>
						</el-form-item>
						<el-form-item label="数量" prop="num">
							<el-input v-model.number="form1.num" clearable placeholder="请输入数量">
							</el-input>
						</el-form-item>
						<el-form-item label="特别备注">
							<el-input type="textarea" v-model.number="form1.mark" clearable
								placeholder="请输入物品颜色、码数、数量等其他具体要求"></el-input>
						</el-form-item>
					</el-form>
				</div>

				<div class="mian-btn">
					<el-button type="primary" @click="purchasing('ruleForm2')">提交订单</el-button>
				</div>
			</div>


			<!-- 在线下单提交订单 -->
			<div class="panel" v-show="step">
				<div class="subtitle rbc">
					<span>核对下单信息</span>
				</div>

				<el-table max-height="200" :data="tableData" stripe border style="width: 100%"
					:header-cell-style="{background:'#EBF6FC',color:'#999999'}">
					<el-table-column type="index" width="45" />
					<el-table-column prop="origin_order_id" label="单号" width="170" />
					<el-table-column prop="origin_delivery" label="快递公司" width="170" />
					<el-table-column prop="product_name" label="物品名称" width="263" />
					<el-table-column prop="product_num" label="数量" width="140" />
					<el-table-column prop="product_price" label="单价" width="140" />
				</el-table>

				<div style="margin-top: 20px;"></div>
				<div class="subtitle rbc">
					<span>收件人信息</span>
				</div>

				<div class="info df">
					<span style="margin-right: 50px;">{{formInline.receive_user}}</span>
					<span style="margin-right: 50px;">{{formInline.receive_address}}</span>
					<span>{{formInline.receive_phone}}</span>
				</div>

				<div class="subtitle rse">
					<span>发票信息</span>
					<div :style="{'margin-left': '20px', 'font-size': '.9rem', color: isReceipt ? '#333' : '#999'}">
						{{isReceipt ? '开具发票' : '不开发票'}}
					</div>

					<el-popconfirm title="是否开具发票？" cancel-button-text="不开具" confirm-button-text="开具"
						@confirm="isReceipt = true" @cancel="isReceipt = false">
						<div slot="reference" style="color: #3F7CF8;margin-left: 20px;font-size: .9rem;">修改</div>
					</el-popconfirm>
				</div>


				<div class="subtitle rsc">
					<span style="margin-right: 20px;">付款方式</span>

					<div class="pay-box">
						<el-radio v-model="payType" label="1" border>余额支付</el-radio>
						<el-radio v-model="payType" label="2" border>微信</el-radio>

						<div class="balance rac">
							<div style="color: #999; font-size: .9rem;">余额可用</div>
							<div style="color: #FE0000">￥{{balance}}</div>
							<el-button size="mini" type="primary" @click="$go('/orderSystem/myBalance')" plain>充值</el-button>
						</div>
					</div>
				</div>

				<div class="total-box">
					<div class="card" style="font-size: .9rem;">
						<div class="rec">
							<span>10件商品, 总运费金额：</span><span class="num">￥500.00</span>
						</div>
						<div class="rec" style="margin-top: 10px;">
							<span>优惠：</span><span class="num">￥500.00</span>
						</div>
					</div>
					<div class="card res" style="font-weight: bold;">
						<span>应付总额：</span><span class="num" style="color:  #FE0000">￥500.00</span>
					</div>
				</div>

				<div class="rec" style="margin-top: 30px;">
					<el-button @click="step=0">返回</el-button>
					<div style="width: 40px;"></div>
					<el-button type="danger">提交订单</el-button>
				</div>
			</div>
		</div>

		<el-dialog title="编辑单号" :visible.sync="dialogFormVisible" center append-to-body>
			<el-form :model="formEditor" :rules="rules" ref="ruleForm1" :inline="true" label-width="90px">
				<el-form-item label="快递公司" prop="delivery">
					<el-input clearable v-model="formEditor.origin_delivery" />
				</el-form-item>
				<el-form-item label="单号" prop="orderId">
					<el-input clearable v-model="formEditor.origin_order_id" />
				</el-form-item>
				<el-form-item label="物品" prop="name">
					<el-input clearable v-model="formEditor.product_name" />
				</el-form-item>
				<el-form-item label="数量" prop="num">
					<el-input clearable v-model.number="formEditor.product_num" />
				</el-form-item>
				<el-form-item label="单价" prop="price">
					<el-input clearable v-model.number="formEditor.product_price" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="editConfirm('ruleForm1')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		regionData,
		CodeToText,
		TextToCode
	} from 'element-china-area-data'

	export default {
		data() {
			return {
				index: 0,
				step: 0,
				ways: ['空运', '海运', '陆运', '代购'],
				dialogVisible: true,
				form: {
					origin_delivery: '',
					origin_order_id: '',
					product_name: '',
					product_num: '',
					product_price: ''
				},
				tableData: [],
				rules: {
					origin_delivery: [{
						required: true,
						message: '请输入快递公司',
						trigger: 'blur'
					}],
					origin_order_id: [{
						required: true,
						message: '请输入订单号',
						trigger: 'blur'
					}],
					product_name: [{
						required: true,
						message: '请输入商品名',
						trigger: 'blur'
					}],
					product_price: [{
						required: true,
						message: '请输入价格',
						trigger: 'blur'
					}],
					product_num: [{
						required: true,
						message: '请输入数量',
						trigger: 'blur'
					}]
				},
				rules1: {
					username: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入地址',
						trigger: 'blur'
					}],
					address_detail: [{
						required: true,
						message: '请输入地址详情',
						trigger: 'blur'
					}],
					num: [{
						required: true,
						message: '请输入数量',
						trigger: 'blur'
					}],
					product: [{
						required: true,
						message: '请输入产品名称',
						trigger: 'blur'
					}]
				},
				formInline: {
					receive_user: '',
					receive_phone: '',
					receive_address: ''
				},
				formEditor: {
					origin_delivery: '',
					origin_order_id: '',
					product_name: '',
					product_num: '',
					product_price: ''
				},
				editorIndex: -1,
				dialogFormVisible: false,
				formLabelWidth: '200px',
				form1: {
					username: '',
					phone: '',
					address: '',
					address_detail: '',
					product: '',
					num: '',
					mark: ''
				},
				options: regionData,
				showTips: false, // 切换下单类型时提示
				isReceipt: false, // 是否开发票
				payType: '1', // 付款方式
				balance:0
			}
		},
		mounted() {
			if (this.$route.query.index) this.index = this.$route.query.index
			this.getUserInfo()
		},
		methods: {
			async getUserInfo() {
				let {
					userInfo
				} = await this.$api.userInfo()
				this.balance = userInfo.balance
			},
			purchasing(formName) {
				this.$refs[formName].validate(async valid => {
					if (valid) {
						await this.$api.purchasing(this.form1)
						this.$message.success('提交成功！');
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			async placeOrder() {
				await this.$api.placeOrder({
					item: this.tableData,
					...this.formInline,
					type: ++this.index
				})
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			deleteRow(index, rows) {
				rows.splice(index, 1);
			},
			handleTab(i) {
				if (i != this.index && this.step) {
					this.$confirm({
						title: '提示',
						content: '此操作将重新下单',
						onOk: () => {
							this.step = 0
							this.index = i
						},
						onCancel: () => {
							this.$msg('已取消');
						}
					})
				} else if (i != this.index) {
					this.index = i
					this.$notify.success({
						title: '提示',
						message: `已选择${this.ways[i]}下单`,
						// position: 'bottom-right',
						offset: 100
					});
				}

			},
			editRow(index) {
				this.dialogFormVisible = true
				this.editorIndex = index
				this.formEditor = {
					...this.tableData[index]
				}
			},
			editConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.tableData.splice(this.editorIndex, 1, this.formEditor)
						this.$message.success('编辑成功！');

						this.dialogFormVisible = false
						this.editorIndex = -1
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			addForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let obj = {
							...this.form
						}

						this.tableData.push(obj)
						this.$message.success('添加成功！');
						this.resetForm(formName)
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			handleOrderNext() {
				this.placeOrder()
				this.step = 1
			},
			handleSubmit() {

			},
			handleChange(value) {
				let address = ''
				value.forEach(v => address += CodeToText[v])
				this.form1.address = address
			}
		}
	}
</script>

<style lang="scss" scoped>
	// /deep/ .el-dialog {
	// 	width: 600px;
	// 	text-align: center;

	// 	.el-dialog__body {
	// 		padding: 50px;

	// 		.el-input {
	// 			width: 300px;
	// 		}

	// 		.el-form-item__label {
	// 			font-size: 1.1rem;
	// 			font-weight: bold;
	// 		}
	// 	}
	// }

	.order-ways {
		width: 100%;
		height: 73px;
		padding: 25px 173px 18px;

		.way {
			background: #F5F5F5;
			width: 165px;
			height: 46px;
			line-height: 46px;
			text-align: center;
			color: #999999;
			font-size: 1rem;
			transition: all .5s linear;
		}

		.way:hover {
			color: #fff;
			background: #55aaff;
			cursor: pointer;
		}

		.active {
			color: #fff;
			background: #3F7CF8;
		}
	}

	.add-number {
		height: 831px;

		.panel {
			height: auto;
			padding: 20px 48px 20px 8px;

			.add {
				.el-input {
					width: 125px;
				}

				/deep/ .el-form-item__label {
					font-size: 1rem;
					color: #333;
					font-weight: bold;
				}

				/deep/ .el-input__inner {
					padding: 0 10px;
				}
			}

			.info {
				width: 100%;
				padding: 10px 20px;
				background: #F6F6F6;

				span {
					display: inline-block;
				}
			}
		}

		.subtitle {
			width: 100%;
			height: 50px;
			margin-bottom: 20px;

			span:nth-child(1) {
				font-size: 1rem;
				font-weight: bold;
				margin-left: 13px;
				position: relative;
				font-family: PingFang SC;

				&::after {
					content: "";
					display: block;
					width: 3px;
					height: 19px;
					background: #3F7CF8;
					position: absolute;
					top: 0;
					left: -13px;
				}
			}
		}

		.add-info {
			padding-left: 20px;

			.el-input {
				width: 280px;
			}

			/deep/ .el-form-item__label {
				font-size: 1rem;
				color: #333;
				font-weight: bold;
			}

			.el-form-item {
				&:nth-child(2n + 2) {
					padding-left: 100px;
					;
				}

				&:last-child {
					.el-input {
						width: 770px;
					}
				}
			}
		}

		.pay-box {
			position: relative;

			.balance {
				width: 200px;
				position: absolute;
				transform: translateY(6px);
			}
		}

		.total-box {
			margin-top: 50px;
			background: #F6F6F6;

			.card {
				padding: 10px 30px;
				min-height: 70px;
				border-bottom: 1px solid #d0d0d0;

				&:last-child {
					border: none;
				}

				.num {
					text-align: right;
					width: 100px;
				}
			}
		}

		.purchasing {
			padding: 0 30px;

			.el-input {
				width: 310px;
				margin-right: 20px;
			}

			/deep/ .el-form-item__label {
				font-size: 1rem;
				color: #333;
				font-weight: bold;
			}

			.el-form-item {
				min-width: 420px;
				margin-bottom: 40px;
			}

			.el-textarea {
				width: 500px;

				/deep/ .el-textarea__inner {
					height: 100px;
				}
			}

			/deep/ .el-cascader {
				width: 310px;
			}

		}
	}

	.number {
		margin: 30px 0 40px;
	}

	.mian-btn {
		width: 302px;
		height: 50px;
		margin: 20px auto 0;
		background: #3F7CF8;
		border-radius: 2px;

		.el-button {
			width: 100%;
			height: 100%;
			font-size: 1.1rem;
		}
	}
</style>
